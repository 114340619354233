export const storageActionTypes = {
    setStorageData: "set storages data",
    toggleViewMode: "toggle view mode",
    setItemSelected: "set item selected",
    closeContextMenu: "close context menu",
    openContextMenu: "open context menu",
    activeReloadData: "active reload Storage Data",
    deactivateReloadData: "deactivate reload Storage Data",
    getFavoriteFolder: "get favorite folder",
    getFavoriteFolderSuccessful: "get favorite folder successful",
    getTrashFolder: "get trash folder",
    getTrashFolderSuccessful: "get trash folder successful",
    getFolderSuccessFul: "[get folder successful]",
    fail: "[fail]",
    createFolder: "[create folder]",
    createFolderSuccessFul: "[create folder successful]",
    favoriteFolder: "favorite folder",
    movetotrashFolder: "move to trash folder",
    movetotrashFolderSuccessFul: "move to trash folder successful",
    renameFolder: "rename folder",
    renameFolderSuccessful: "rename folder successful",
    unfavoriteFolder: "unfavorite folder",
    unfavoriteFolderSuccessful: "unfavorite folder successful",
    moveFolder: "move folder",
    moveFolderSuccessful: "move folder successful",
    copyFolder: "copy folder",
    copyFolderSuccessful: "copy folder successful",
    deleteFolder: "delete folder",
    deleteFolderSuccessful: "delete folder successful",
    recoverFolder: "recover folder",
    recoverFolderSuccessful: "recover folder successful",
    monitorFolder: "monitor folder",
    monitorFolderSuccessful: "monitor folder successful",
    downloadFolder: "download folder",
    downloadFolderSuccessful: "download folder successful",

    renameFile: "rename file",
    renameFileSuccessful: "rename file successful",
    favoriteFile: "favorite file",
    favoriteFileSuccessful: "favorite file successful",
    unfavoriteFile: "unfavorite file",
    unfavoriteFileSuccessful: "unfavorite file successful",
    uploadFile: "upload file",
    uploadFileSuccessFul: "up load file successful",
    moveFile: "move file",
    moveFileSuccessful: "move file successful",
    copyFile: "copy file",
    copyFileSuccessful: "copy file successful",
    movetotrashFile: "move to trash file",
    movetotrashFileSuccessFul: "move to trash file successful",
    deleteFile: "delete file",
    deleteFileSuccessful: "delete file successful",
    recoverFile: "recover file",
    recoverFileSuccessful: "recover file successful",
    downloadFile: "download file",
    downloadFileSuccessful: "download file successful",

    updateTempId: "update temp id",
    updateMethod: "update method",
    updatePopupMove: "popup move",
    updateBreadcrumb: "breadcrumb",
    updatePopupDetail: "popup detail",
    recentFolder: "recent folder",
    recentFolderSuccessful: "recent folder successful",

    // chooseCategoryToAdd: "[choose category]",
    // getListRecommend: "[get list recommend]",
    // geteListRecommendSuccessful: "[get list recommend category successu]"
};
