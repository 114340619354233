export const CATEGORY = "CATEGORY";
export const PROFILE = "PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESSFUL = "UPDATE_PROFILE_SUCCESSFUL";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const ALERT_SETTING = "ALERT_SETTING";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_SUCCESSFUL = "UPDATE_EMAIL_SUCCESSFUL";
export const UPDATE_EMAIL_FAIL = "UPDATE_EMAIL_FAIL";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESSFUL = "UPDATE_PASSWORD_SUCCESSFUL";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const LOGOUT = "LOGOUT";
export const SEND_VERIFY = "SEND_VERIFY";
export const SEND_VERIFY_SUCCESSFUL = "SEND_VERIFY_SUCCESSFUL";
export const SEND_VERIFY_FAIL = "SEND_VERIFY_FAIL";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const VERIFY_PASSWORD_SUCCESSFUL = "VERIFY_PASSWORD_SUCCESSFUL";
export const VERIFY_PASSWORD_FAIL = "VERIFY_PASSWORD_FAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESSFUL = "VERIFY_EMAIL_SUCCESSFUL";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";
