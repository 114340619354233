export const ENGINE_BLOCKCHAIN = "ENGINE_BLOCKCHAIN";
export const CONSENSUS = "CONSENSUS";
export const NUMBER_NODES = "NUMBER_NODES";
export const NUMBER_OF_PEERS = "NUMBER_OF_PEERS";
export const CLUSTER_NAME = "CLUSTER_NAME";
export const ADD_ORGANIZATIONS = "ADD_ORGANIZATIONS";
export const NUMBER_ORGANIZATIONS = "NUMBER_ORGANIZATIONS";
export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";
export const DELETE_ORGANIZATIONS = "DELETE_ORGANIZATIONS";
export const NODE_PLAN = "NODE_PLAN";
export const VIEW_MORE = "VIEW_MORE";
export const GET_NETWORK = "GET_NETWORK";
export const GET_NETWORK_SUCCESSFUL = "GET_NETWORK_SUCCESSFUL";
export const DELETE_NETWORK = "DELETE_NETWORK";
export const DELETE_NETWORK_SUCCESSFUL = "DELETE_NETWORK_SUCCESSFUL";
export const GET_NETWORK_BY_ID = "GET_NETWORK_BY_ID";
export const GET_NETWORK_BY_ID_SUCCESSFUL = "GET_NETWORK_BY_ID_SUCCESSFUL";
export const NAVIGATE = "NAVIGATE";
