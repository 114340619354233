export const GET_DAPPS = "GET_DAPPS";
export const GET_DAPPS_SUCCESSFUL = "GET_DAPPS_SUCCESSFUL";

export const CREATE_DAPP = "CREATE_DAPP";
export const CREATE_DAPP_SUCCESSFUL = "CREATE_DAPP_SUCCESSFUL";

export const SELECTED_NODE = "SELECTED_NODE";
export const STEP1_DATA = "STEP1_DATA";
export const STEP2_ENTITIES = "STEP2_ENTITIES";
export const STEP2_RELATIONSHIPS = "STEP2_RELATIONSHIPS";
export const NEW_DAPP_LOGO = "NEW_DAPP_LOGO";
export const ALERT = "ALERT";
export const CURRENT_DAPP = "CURRENT_DAPP";

export const STATE_CONNECTING_ENTITY = "STATE_CONNECTING_ENTITY";
export const CLEAR_DAPP_STATE = "CLEAR_DAPP_STATE";
