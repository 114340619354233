// export default [
//     {
//         name: "2GB/1CPU",
//         id: 0,
//         storage: 25,
//         ram: 2,
//         cpu: 1,
//         transfer: 1,
//     },
//     {
//         name: "3GB/1CPU",
//         id: 1,
//         storage: 50,
//         ram: 3,
//         cpu: 1,
//         transfer: 2,
//     },
//     {
//         name: "2GB/2CPU",
//         id: 2,
//         storage: 60,
//         ram: 2,
//         cpu: 2,
//         transfer: 3,
//     },
//     {
//         name: "1GB/3CPU",
//         id: 3,
//         storage: 60,
//         ram: 1,
//         cpu: 3,
//         transfer: 3,
//     },
//     {
//         name: "4GB/2CPU",
//         id: 4,
//         storage: 60,
//         ram: 4,
//         cpu: 2,
//         transfer: 3,
//     },
//     {
//         name: "4GB/2CPU",
//         id: 5,
//         storage: 60,
//         ram: 4,
//         cpu: 2,
//         transfer: 3,
//     },
//     {
//         name: "8GB/4CPU",
//         id: 6,
//         storage: 60,
//         ram: 8,
//         cpu: 4,
//         transfer: 3,
//     },
//     {
//         name: "16GB/6CPU",
//         id: 7,
//         storage: 60,
//         ram: 16,
//         cpu: 6,
//         transfer: 3,
//     },
//     {
//         name: "16GB/8CPU",
//         id: 8,
//         storage: 60,
//         ram: 16,
//         cpu: 8,
//         transfer: 3,
//     },
//     {
//         name: "32GB/8CPU",
//         id: 9,
//         storage: 60,
//         ram: 32,
//         cpu: 8,
//         transfer: 3,
//     },
// ];

export default [
    {
        name: "2GB/1CPU",
        id: 0,
        storage: 25,
        ram: 2,
        cpu: 1
    },
    {
        name: "2GB/2CPU",
        id: 1,
        storage: 25,
        ram: 2,
        cpu: 2
    },
    {
        name: "4GB/2CPU",
        id: 2,
        storage: 25,
        ram: 4,
        cpu: 2
    },
    {
        name: "8GB/4CPU",
        id: 3,
        storage: 25,
        ram: 8,
        cpu: 4
    },
    {
        name: "16GB/8CPU",
        id: 4,
        storage: 25,
        ram: 16,
        cpu: 8
    }
]